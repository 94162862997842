import React from 'react';
import { Link, graphql } from 'gatsby';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
import Img from 'gatsby-image';

const Projects = (props) => {
  const projects = props.data.allMarkdownRemark.edges;
  return (
    <Layout bodyClass="page-projects">
      <SEO title="Projects" />
      <div className="intro mt-5 mt-lg-0">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Our Projects</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="container pb-6">
        <div className="row">
          {projects.map(edge => (
            <div key={edge.node.frontmatter.path} className="col-12 col-md-4 mb-1">
              <div className="card service service-teaser">
                <div className="card-content">
                  <Link to={edge.node.frontmatter.path}><Img fluid={edge.node.frontmatter.image.childImageSharp.fluid} alt={edge.node.frontmatter.image.childImageSharp.alt} /></Link>
                  <p className="project-title mb-0 mt-1">
                    <Link to={edge.node.frontmatter.path}>{edge.node.frontmatter.title}</Link>
                  </p>
                  <p>{edge.node.excerpt}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ProjectsQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/projects/" } }
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            title
            path
            image {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Projects;
